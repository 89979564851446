<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="steps-nav">
        <van-steps :active="active">
          <van-step>回答问题</van-step>
          <van-step>推荐方案</van-step>
          <van-step>预约</van-step>
        </van-steps>
      </div>

      <div class="risk-box">
        <div class="page-index-risk">
          <div class="page-index-risk-tips">
            <h3>我发现了 <span>3</span> 个健康风险</h3>
            <span
              >根据您的生活习惯和家族史以及历史疾病分析，您有以下健康风险并及时做相关检查</span
            >
          </div>
          <img src="../../assets/img/home/img-ai-helper@2x.png" />
        </div>
        <div class="page-index-bodyer">
          <div
            class="page-index-bodyer-item"
            v-for="(item, index) of list"
            :key="'risk-' + index"
            @click="onTap(index, list)"
          >
            <div
              :class="[
                item.sel
                  ? 'page-index-bodyer-item-circle page-index-bodyer-item-circle-disable'
                  : 'page-index-bodyer-item-circle',
              ]"
            >
              <van-icon name="success" />
            </div>
            <div class="page-index-bodyer-item-content">
              <h3>{{ item.name }}</h3>
              <p>{{ item.des }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="risk-box">
        <div class="page-index-bodyer">
          <h3 class="risk-box-title">风险排筛</h3>
          <p class="risk-box-des">
            根据您的家族史以及生活习惯有较高的患病可能。疾病预防需早发现早治疗，建议尽快做以下检查检验
          </p>
          <div class="page-index-bodyer-item">
            <div class="page-index-bodyer-item-circle">
              <van-icon name="flag-o" />
            </div>
            <div class="page-index-bodyer-item-content">
              <h3>检查</h3>
              <p class="items">
                <a href="#@">盆腔超声</a> <a href="#@">盆腔超声</a>
              </p>
            </div>
          </div>

          <div class="page-index-bodyer-item">
            <div class="page-index-bodyer-item-circle">
              <van-icon name="notes-o" />
            </div>
            <div class="page-index-bodyer-item-content">
              <h3>检验</h3>
              <p class="items">
                <a href="#@">盆腔超声</a> <a href="#@">盆腔超声</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="risk-form-box-btn" @click="onTapRisk">预约检查</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      list: [
        {
          name: "消化系统风险",
          des: "家族史、暴饮暴食、高脂饮食因素诱发",
          sel: false,
        },
        {
          name: "神经风险",
          sel: false,
          des: "家族史、暴饮暴食、高脂饮食因素诱发",
        },
        {
          name: "骨质酥松风险",
          sel: false,
          des: "碳酸饮料 年龄等因素诱发",
        },
      ],

      loading: false,
      active: 1,
      sel_list: [],
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {},
  methods: {
    onTap(index, list) {
      list[index].sel = !list[index].sel;
    },
    onTapRisk() {
      //this.$router.push({ path: "/health-check-result" });
    },
  },
};
</script>

<style scoped>
.g_main_content {
  background: #f2f7f8;
}
.steps-nav {
  background: #fff;
  padding: 20px 16px;
  box-shadow: 0 2px 6px #ebedf0;
}

.risk-form-box-btn {
  height: 44px;
  display: block;
  text-align: center;
  color: #fff;
  background: #1890ff;
  font-size: 16px;
  line-height: 44px;
  border-radius: 22px;
  font-weight: 600;
  margin: 20px 14px;
}

.risk-box {
  margin-top: 20px;
  margin: 20px 16px;
}

.page-index-bodyer {
  background: #fff;
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.page-index-bodyer-item {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px 0px;
}

.page-index-bodyer-item-content {
  flex: 1;
  padding-left: 10px;

  & h3 {
    font-size: 16px;
    font-weight: 500;
  }

  & p {
    font-size: 14px;
    color: #666666;
    line-height: 1.8;
  }
}

.page-index-bodyer-item-circle {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  background: #3388ff;
  border: 1px #3388ff solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-index-bodyer-item-circle-disable {
  border: 1px #3388ff solid;
  background: #fff;
}

.page-index-risk {
  background: #fffbec;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 130px;
  -webkit-box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0);
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 144px;
    margin-top: 13px;
  }
}

.page-index-risk-tips {
  margin-left: 12px;
  line-height: 1.2;
  & h3 {
    font-size: 16px;
    font-weight: 600;
    color: #242938;
    & span {
      color: #ff7823;
      font-size: 18px;
    }
  }
  & span {
    color: #707070;
    font-size: 12px;
  }
}

.page-index-risk-tips-btn {
  margin-top: 10px;
  background: #ff7823;
  width: 95px;
  height: 30px;
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  font-weight: 400;
}

.risk-box-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
}
.risk-box-des {
  font-size: 14px;
  color: #707070;
  margin-bottom: 10px;
}
.items {
  margin-top: 6px;
}
.items a {
  color: #1890ff;
  margin-right: 4px;
}
</style>
