var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g_page_box"},[_c('div',{staticClass:"g_main_content"},[_c('g-loading',{attrs:{"loading":_vm.loading}}),_c('div',{staticClass:"steps-nav"},[_c('van-steps',{attrs:{"active":_vm.active}},[_c('van-step',[_vm._v("回答问题")]),_c('van-step',[_vm._v("推荐方案")]),_c('van-step',[_vm._v("预约")])],1)],1),_c('div',{staticClass:"risk-box"},[_vm._m(0),_c('div',{staticClass:"page-index-bodyer"},_vm._l((_vm.list),function(item,index){return _c('div',{key:'risk-' + index,staticClass:"page-index-bodyer-item",on:{"click":function($event){return _vm.onTap(index, _vm.list)}}},[_c('div',{class:[
              item.sel
                ? 'page-index-bodyer-item-circle page-index-bodyer-item-circle-disable'
                : 'page-index-bodyer-item-circle',
            ]},[_c('van-icon',{attrs:{"name":"success"}})],1),_c('div',{staticClass:"page-index-bodyer-item-content"},[_c('h3',[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(item.des))])])])}),0)]),_c('div',{staticClass:"risk-box"},[_c('div',{staticClass:"page-index-bodyer"},[_c('h3',{staticClass:"risk-box-title"},[_vm._v("风险排筛")]),_c('p',{staticClass:"risk-box-des"},[_vm._v(" 根据您的家族史以及生活习惯有较高的患病可能。疾病预防需早发现早治疗，建议尽快做以下检查检验 ")]),_c('div',{staticClass:"page-index-bodyer-item"},[_c('div',{staticClass:"page-index-bodyer-item-circle"},[_c('van-icon',{attrs:{"name":"flag-o"}})],1),_vm._m(1)]),_c('div',{staticClass:"page-index-bodyer-item"},[_c('div',{staticClass:"page-index-bodyer-item-circle"},[_c('van-icon',{attrs:{"name":"notes-o"}})],1),_vm._m(2)])])]),_c('div',{staticClass:"risk-form-box-btn",on:{"click":_vm.onTapRisk}},[_vm._v("预约检查")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-index-risk"},[_c('div',{staticClass:"page-index-risk-tips"},[_c('h3',[_vm._v("我发现了 "),_c('span',[_vm._v("3")]),_vm._v(" 个健康风险")]),_c('span',[_vm._v("根据您的生活习惯和家族史以及历史疾病分析，您有以下健康风险并及时做相关检查")])]),_c('img',{attrs:{"src":require("../../assets/img/home/img-ai-helper@2x.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-index-bodyer-item-content"},[_c('h3',[_vm._v("检查")]),_c('p',{staticClass:"items"},[_c('a',{attrs:{"href":"#@"}},[_vm._v("盆腔超声")]),_vm._v(" "),_c('a',{attrs:{"href":"#@"}},[_vm._v("盆腔超声")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-index-bodyer-item-content"},[_c('h3',[_vm._v("检验")]),_c('p',{staticClass:"items"},[_c('a',{attrs:{"href":"#@"}},[_vm._v("盆腔超声")]),_vm._v(" "),_c('a',{attrs:{"href":"#@"}},[_vm._v("盆腔超声")])])])
}]

export { render, staticRenderFns }